import { DefaultButton, PrimaryButton, Stack, TextField } from "@fluentui/react";
import * as React from "react";
import HeroList from "./HeroList";

export interface HeaderProps {
  message: string;
}

export default class Header extends React.Component<HeaderProps> {
  render() {
    const purchaseLicenseKey = () => {
      window.open("https://littleappyco.gumroad.com/l/attendeemanager");
    }

    const { message } = this.props;

    const listItems = [
      {
          icon: "SearchCalendar",
          primaryText: "Email attendees that haven't responded",
      },
      {
          icon: "EventAccepted",
          primaryText: "Send an email to those attending",
      },
      {
          icon: "DownloadDocument",
          primaryText: "Export attendee lists to a CSV file",
      }
  ];

    return (
      <section className="ms-welcome__header ms-bgColor-neutralLighter ms-u-fadeIn500">
        <HeroList message="Easily email or export meeting attendees based on their response status" items={listItems} />
        <DefaultButton text="Get License Key" onClick={purchaseLicenseKey} allowDisabledFocus />
      </section>
    );
  }
}

import * as React from "react";
import { DefaultButton, Dropdown, Label, Text, Stack, IDropdownProps, MessageBar, MessageBarType, Separator, ActionButton, PrimaryButton } from "@fluentui/react";
import { useEffect, useState } from "react";

export interface ContactFormProps {
}

export const ContactForm = ({ setHasLicenseKeySet }) => {
    const [selectedResponseStatusTypes, setSelectedResponseStatusTypes] = useState(['none']);
    const [selectedAttendanceCategories, setSelectedAttendanceCategories] = useState(['required', 'optional']);
    const [estimatedAttendeeCount, setEstimatedAttendeeCount] = useState(undefined);

    useEffect(() => {
        var attendees = fetchAttendees();
        setEstimatedAttendeeCount(attendees.length)
    }, [selectedResponseStatusTypes, selectedAttendanceCategories]);

    const helpAndSupport = () => {
        window.open("https://littleappy.co/help");
    }

    const fetchAttendees = () => {
        var attendees = [];

        // Fetch required attendees if customer has selected
        if (selectedAttendanceCategories.indexOf("required") !== -1) {
            var requiredAttendees = Office.context.mailbox.item?.requiredAttendees;
            if (requiredAttendees.length > 0) {
                attendees = attendees.concat(requiredAttendees);
            }
        }

        // Fetch optional attendees if customer has selected
        if (selectedAttendanceCategories.indexOf("optional") !== -1) {
            var optionalAttendees = Office.context.mailbox.item?.optionalAttendees;
            if (optionalAttendees.length > 0) {
                attendees = attendees.concat(optionalAttendees);
            }
        }

        // Filter attendees by status
        attendees = attendees.filter((attendee) =>
            selectedResponseStatusTypes.includes(attendee.appointmentResponse));

        return attendees;
    }

    const click = async () => {
        var attendees = fetchAttendees().map(attendee => attendee.emailAddress);

        // As per documentation, each TO/CC field accepts max 100 recipients
        // https://docs.microsoft.com/en-us/javascript/api/outlook/office.mailbox?view=outlook-js-1.9&preserve-view=true#outlook-office-mailbox-displaynewmessageformasync-member(1)
        const maxRecipientsPerField = 99;
        var toRecipients = attendees.slice(0, maxRecipientsPerField);
        var ccRecipients = attendees.slice(maxRecipientsPerField, maxRecipientsPerField);

        Office.context.mailbox.displayNewMessageFormAsync({
            toRecipients: toRecipients,
            ccRecipients: ccRecipients,
            subject: "RE: " + Office.context.mailbox.item.subject
        }, (result) => {
            console.log(result)
        });
    };

    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }

    async function copyCsvToClipboard() {
        var attendees = fetchAttendees().map((attendee) => {
            return attendee.displayName.replace(",", "") + "," +
            attendee.appointmentResponse.replace(",", "") + "," +
            attendee.emailAddress.replace(",", "") + "," +
            attendee.recipientType.replace(",", "") + ""
        })

        var csvArray = ["displayName,appointmentResponse,emailAddress,recipientType"].concat(attendees)

        // if (!navigator.clipboard && await navigator.permissions.query({name:'clipboard-write'})) {
            fallbackCopyTextToClipboard(csvArray.join("\n"));
            return;
        // }
        // navigator.clipboard.writeText(csvArray).then(function () {
        //     console.log('Async: Copying to clipboard was successful!');
        // }, function (err) {
        //     console.error('Async: Could not copy text: ', err);
        // });
    }

    const clearLicenseKey = () => {
        Office.context.roamingSettings.remove("l");
        Office.context.roamingSettings.saveAsync();
        setHasLicenseKeySet(false);
    }

    const stackTokens = { childrenGap: 10 };
    const responseStatusOptions = [
        { key: 'accepted', text: 'Accepted' },
        { key: 'tentative', text: 'Tentative' },
        { key: 'declined', text: 'Declined' },
        { key: 'none', text: 'No Response' }
    ];

    const attendanceCategoryOptions = [
        { key: 'required', text: 'Required' },
        { key: 'optional', text: 'Optional' }
    ]

    const onRenderRequiredLabel = (props: IDropdownProps): JSX.Element => {
        return (
            <Label required>{props.label}</Label>
        );
    };

    const onResponseStatusDropdownDidChange = (_, option) => {
        var selectedResponseStatusTypesSet = new Set(selectedResponseStatusTypes);
        if (option.selected) {
            selectedResponseStatusTypesSet.add(option.key)
        } else {
            selectedResponseStatusTypesSet.delete(option.key)
        }
        console.log(selectedResponseStatusTypesSet)
        setSelectedResponseStatusTypes(Array.from(selectedResponseStatusTypesSet))
    }

    const onAttendanceCategoryDropdownDidChange = (_, option) => {
        var selectedAttendanceSet = new Set(selectedAttendanceCategories);
        if (option.selected) {
            selectedAttendanceSet.add(option.key)
        } else {
            selectedAttendanceSet.delete(option.key)
        }
        setSelectedAttendanceCategories(Array.from(selectedAttendanceSet))
    }

    return (
        <div className="ms-welcome__main">
            <p className="ms-font-l">
                Configure the attendees you wish to communicate with or export.
            </p>
            <Stack tokens={stackTokens} horizontalAlign={'start'}>
                <div hidden={estimatedAttendeeCount < 200}>
                    <MessageBar
                        messageBarType={MessageBarType.warning}
                        isMultiline={false}
                    >
                        More than 200 recipients selected. Due to Microsoft limits, a maximum of 200 will be added & cc'd in any emails created.
                    </MessageBar>
                </div>

                <Stack tokens={stackTokens} horizontalAlign={'start'}>
                    <div className="fullWidth">
                        <Dropdown
                            defaultSelectedKeys={selectedResponseStatusTypes}
                            placeholder="Select an option"
                            onChange={onResponseStatusDropdownDidChange}
                            label="Response Status"
                            onRenderLabel={onRenderRequiredLabel}
                            options={responseStatusOptions}
                            errorMessage={selectedResponseStatusTypes.length < 1 ? 'Please select a value.' : undefined}
                            multiSelect
                        />
                    </div>
                    <Text block variant="smallPlus">The response status is determined via replies from attendees for this appointment.</Text>
                </Stack>

                <Stack tokens={stackTokens} horizontalAlign={'start'}>
                    <div className="fullWidth">
                        <Dropdown
                            defaultSelectedKeys={selectedAttendanceCategories}
                            placeholder="Select an option"
                            onChange={onAttendanceCategoryDropdownDidChange}
                            label="Attendance Category"
                            onRenderLabel={onRenderRequiredLabel}
                            options={attendanceCategoryOptions}
                            errorMessage={selectedAttendanceCategories.length < 1 ? 'Please select a value.' : undefined}
                            multiSelect
                        />
                    </div>
                    <Text block variant="smallPlus">The attendance category reflects whether the organiser marked an attendee as required or optional for this appointment.</Text>
                </Stack>

                <div className="fullWidth topPadded10">
                    <Separator>{estimatedAttendeeCount} attendee{(estimatedAttendeeCount == 1 ? '' : 's')} selected</Separator>
                </div>

                <div className="fullWidth">
                    <Stack tokens={stackTokens} horizontalAlign={'center'} horizontal>
                        <PrimaryButton
                            className="ms-welcome__action"
                            iconProps={{ iconName: "NewMail" }}
                            text={'Send email'}
                            disabled={estimatedAttendeeCount < 1 || selectedAttendanceCategories.length < 1 || selectedResponseStatusTypes.length < 1 || estimatedAttendeeCount > 200}
                            onClick={click} />

                        <div style={estimatedAttendeeCount < 1 || selectedAttendanceCategories.length < 1 || selectedResponseStatusTypes.length < 1 || estimatedAttendeeCount > 200 ? { pointerEvents: "none" } : {}}>
                            <DefaultButton
                                disabled={estimatedAttendeeCount < 1 || selectedAttendanceCategories.length < 1 || selectedResponseStatusTypes.length < 1 || estimatedAttendeeCount > 200}
                                className="ms-welcome__action"
                                iconProps={{ iconName: "DownloadDocument" }}
                                text={'Copy CSV data'}
                                onClick={copyCsvToClipboard} />
                        </div>
                    </Stack>

                    <Stack tokens={stackTokens} horizontalAlign={'center'} horizontal className="deleteLicenseKeyButton">
                        <ActionButton allowDisabledFocus onClick={clearLicenseKey}>
                            <Text variant="small">Delete License Key</Text>
                        </ActionButton>
                        <ActionButton allowDisabledFocus onClick={helpAndSupport}>
                            <Text variant="small">Help & Support</Text>
                        </ActionButton>
                    </Stack>
                </div>
            </Stack>
        </div>
    );
}

import { PrimaryButton, Spinner, Stack, TextField, Text } from "@fluentui/react";
import React, { useState } from "react"
import Header from "./Header";

export const SignUpView = ({ setHasLicenseKeySet }) => {
    const [licenseKey, setLicenseKey] = useState("");
    const [licenseKeyError, setLicenseKeyError] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const validateLicenseKey = async () => {
        setLicenseKeyError(undefined);
        setIsLoading(true);

        if (licenseKey.trim().length == 0){
            // Invalid license key, so show error
            setLicenseKeyError("No license key provided")
            setIsLoading(false);
            return;
        }

        const response = await fetch('https://qrcodegenerator.api.littleappy.co/licensekey/attendeemanager', {
            method: 'POST',
            headers: {
                Accept: 'application.json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                license: licenseKey
            })
        })

        setIsLoading(false);

        if (!response.ok) {
            // Invalid license key, so show error
            setLicenseKeyError("Invalid license key, please try again")
            return;
        }

        Office.context.roamingSettings.set("l", licenseKey);
        Office.context.roamingSettings.saveAsync();
        setHasLicenseKeySet(true);
    }

    return (
        <div>
            <Header message="Get Started" />
            <Stack tokens={{ childrenGap: 16 }} horizontalAlign={"center"} verticalAlign={"end"}>
                <TextField
                    label="Enter your license key to get started"
                    placeholder="XXXX-XXXXX-XXXX-XXXX"
                    required
                    className="licenseKeyTextField topPadded10"
                    errorMessage={licenseKeyError}
                    onChange={(_, newValue) => setLicenseKey(newValue)} />

                {isLoading &&
                    <Spinner label="Verifying license key..." ariaLive="assertive" labelPosition="right" />}

                {!isLoading && <PrimaryButton className="licenseKeyTextField" text="Save" onClick={validateLicenseKey} allowDisabledFocus />}
            </Stack>
        </div>
    )
}
import * as React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import Header from "./Header";

export interface ProgressProps {
  logo: string;
  message: string;
  title: string;
}

export default class Progress extends React.Component<ProgressProps> {
  render() {
    const { logo, message, title } = this.props;

    return (
      <section className="ms-welcome__progress ms-u-fadeIn500">
        <Header message="Get Started" />
        <Spinner size={SpinnerSize.large} label={message} className="topPadded10" />
      </section>
    );
  }
}

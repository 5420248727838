import * as React from "react";
import { ContactForm } from "./ContactForm";
import Progress from "./Progress";
import { SignUpView } from "./SignUpView";
import { useState } from "react";

/* global require */

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface AppState {
}

// export default class App extends React.Component<AppProps, AppState> {
const App  = (props: AppProps, _: AppState) => {
    const { title, isOfficeInitialized } = props;

    console.log("Office:" + isOfficeInitialized);
    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={require("./../../../assets/logo-filled.png")}
          message="Please sideload the add in to use this app."
        />
      );
    }

    // Set only if loaded within Office context, otherwise progress view doesn't load due to exception
    const [hasLicenseKeySet, setHasLicenseKeySet] = useState(Office.context.roamingSettings.get("l"));

    var viewToShow = hasLicenseKeySet ? <ContactForm setHasLicenseKeySet={setHasLicenseKeySet} /> : <SignUpView setHasLicenseKeySet={setHasLicenseKeySet} />

    return (
      <div className="ms-welcome">
        {viewToShow}
      </div>
    );
}

export default App;
